import { Alert, Box, Button, Paper, Typography } from "@mui/material";
import { useEffect, useState } from "react";
import Body from "../components/Body"
import ImageOfTheDay from "../components/ImageOfTheDay";
import RecentPrograms from "../components/RecentPrograms";
import { useApiCalls } from "../contexts/ApiCallsProvider";
import { useStyles } from "../contexts/StylesProvider";
import { useEnsureTab } from "../contexts/TabsProvider"
import { useUser } from "../contexts/UserProvider";
import { Link } from "react-router-dom";
import { useImagesGroup } from "../contexts/ImageGroupProvider";
import Grid2 from "@mui/material/Unstable_Grid2";

const DashboardPage = () => {
    const ensureTab = useEnsureTab();
    const styles = useStyles();
    const { user } = useUser();
    const {
        getImageCounts,
    } = useApiCalls();

    const { resetContext } = useImagesGroup();

    const [ imageCounts, setImageCounts ] = useState(
        {
            loaded: false,
            unconfirmed: 0,
            searchable: 0,
            deleted: 0
        });

    useEffect(() => {
        resetContext();
    },[resetContext]);
   
    useEffect(() => {
        ensureTab('/dashboard');
    },[ensureTab]);

    useEffect(() => {
        if (imageCounts.loaded === false) {
            (async () => {
                const result = await getImageCounts();
                setImageCounts({...result, loaded: true});
            })();
        };
    },[getImageCounts, imageCounts.loaded])

    const renderConfirm = () => {
        if (imageCounts.unconfirmed > 0) {
            return(
                <Box sx={{ ...styles.boxFlexColAlignItemsCenter }}>
                    <Typography> There {imageCounts.unconfirmed > 1 ? 'are ' + imageCounts.unconfirmed + ' images' : 'is one lonely image'} to validate.</Typography>
                    <Button variant='contained' color='primary' href='/images/confirm' sx={{ mt: 1 }}>Validate Images</Button>
                </Box>
            )
        }
    }

    const renderPasswordExpirationWarning = () => {
        return user.password_expiration_days <= process.env.REACT_APP_PASSWORD_EXPIRATION_IMPENDING_WARNING_DAYS;
    };

    const renderPasswordExpirationText = () => {
        //days > 0
        return user.password_expiration_days > 0 ?
            `Your password will expire in ${user.password_expiration_days} day${user.password_expiration_days > 1 ? 's' : null}.`
        //days = 0
        : `Your password expires today.`
    };

    return (
        <Body>
            {renderPasswordExpirationWarning() &&
            <Box>
                <Alert severity='warning'>
                    {renderPasswordExpirationText()} Click <Link to="/password">here</Link> to change it now.
                </Alert>
            </Box>
            }
            <Box id='mainContentBox' sx={styles.boxMainContent}>
                <Paper sx={styles.paperBody} elevation={3}>
                    <Box id='box-dashboard-header' sx={styles.boxBodyHeader}>
                        <Typography color='primary' sx={styles.textPageTitle}>Dashboard</Typography>
                        {imageCounts.searchable === 0
                            ? <Typography>The {process.env.REACT_APP_NAME !==null ? process.env.REACT_APP_NAME + ' ' : null} database is empty.</Typography>
                            : <Typography>The {process.env.REACT_APP_NAME !==null ? process.env.REACT_APP_NAME + ' ' : null} database houses {imageCounts.searchable > 1 ? imageCounts.searchable + ' searchable images' : 'one solitary image'}.</Typography>
                        }
                    </Box>
                    <Grid2 id='box-dashboard-maincontent' container spacing={4} sx={{ display: 'flex', justifyContent: 'center'}}>
                        <Grid2 size={{ xs: 12, md: 6 }}>
                            <RecentPrograms />
                        </Grid2>
                        <Grid2 size={{ xs: 12, md: 6 }}>
                            <ImageOfTheDay />
                        </Grid2>
                        <Grid2 size={{ xs: 12, md: 6 }}>
                            {renderConfirm()}
                        </Grid2>
                    </Grid2>
                </Paper>
            </Box>
        </Body>
    );
};

export default DashboardPage;