import { AppBar, Box, Button, IconButton, Menu, MenuItem, Tab, Tabs, Toolbar, Typography } from "@mui/material";
import { Fragment, useContext, useState } from "react";
import { NavLink, useNavigate } from "react-router-dom";
import MenuIcon from '@mui/icons-material/Menu';

import { useUser } from "../contexts/UserProvider";
import { TabsContext } from "../contexts/TabsProvider";
import { useStyles } from '../contexts/StylesProvider';
import { inArray } from "../utils/utils";

const Header = () => {

    const { currentTab, setCurrentTab } = useContext(TabsContext);

    const { user, logout } = useUser();
    const styles = useStyles();
    const navigate = useNavigate();

    const [ anchorEl, setAnchorEl ] = useState(null);

    const handleMenu = (event) => {
        setAnchorEl(event.currentTarget);
    };

    const handleClose = (e,l=false) => {
        setAnchorEl(null);
        if (Boolean(l)) {
            logout();
        };
    };

    const handleLogoClick = () => {
        if (user && user.su === true) {
            navigate('/Su');
        };
    };

    document.title = process.env.REACT_APP_NAME

    return (
        <Fragment>
            <AppBar position="static">
                <Toolbar>
                    <img
                        id="org_logo"
                        src="/static/org-logo.png"
                        alt="organization logo"
                        style={{ padding: 7, width: 80 }}
                        onClick={handleLogoClick}
                        />
                    <Typography variant="h6" style={{ flexGrow: 1, paddingLeft: 10 }} sx={{ fontWeight: 'bold' }}>
                        {process.env.REACT_APP_NAME}
                    </Typography>
                    {user !== null && 
                        <Fragment>
                            <Box sx={styles.responsiveDisplayMobile}>
                                <IconButton
                                    size='large'
                                    sx={{ color: 'white' }}
                                    onClick={handleMenu}
                                >
                                    <MenuIcon />    
                                </IconButton>
                                <Menu
                                    id='menu-appbar'
                                    anchorEl={anchorEl}
                                    anchorOrigin={{
                                        vertical: 'top',
                                        horizontal: 'right',
                                    }}
                                    keepMounted
                                    transformOrigin={{
                                        vertical: 'top',
                                        horizontal: 'right',
                                    }}
                                    open={Boolean(anchorEl)}
                                    onClose={(e) => handleClose(e,false)}
                                >
                                    <MenuItem onClick={handleClose} component={NavLink} to="/dashboard">Dashboard</MenuItem>
                                    <MenuItem onClick={handleClose} component={NavLink} to="/programs">Programs</MenuItem>
                                    <MenuItem onClick={handleClose} component={NavLink} to="/search">Search</MenuItem>
                                    <MenuItem onClick={handleClose} component={NavLink} to="/import">Import</MenuItem>
                                    <MenuItem onClick={handleClose} component={NavLink} to="/images">Images</MenuItem>
                                    <MenuItem onClick={handleClose} component={NavLink} to="/collections">Collections</MenuItem>
                                    <MenuItem onClick={handleClose} component={NavLink} to="/settings">Settings</MenuItem>
                                    <MenuItem onClick={(e) => handleClose(e,true)}>Logout</MenuItem>
                                </Menu>
                            </Box>
                            <Box sx={styles.responsiveDisplayNonMobile}>
                                <Button id='button-logout' onClick={logout} style={{ color: 'white' }}><Typography variant='caption' sx={{ fontWeight: 'bold' }}>Log out</Typography></Button>
                            </Box>
                        </Fragment>
                    }
                </Toolbar>
            </AppBar>
            {user && 
                <Tabs id='tabs-navigation'
                    centered
                    indicatorColor="primary"
                    textColor="primary"
                    value={currentTab}
                    onChange={(event,index) => setCurrentTab(index)}
                    sx={{ display: { xs: 'none', md: 'flex' }}}
                >
                    <Tab id='tab-dashboard' label="Dashboard" value="/dashboard" component={NavLink} to="/dashboard" />
                    {user.rights.includes('view') && <Tab id='tab-programs' name='tab-programs' label="Programs" value="/programs" component={NavLink} to="/programs" />}
                    {user.rights.includes('view') && <Tab id='tab-search' name='tab-search' label="Search" value="/search" component={NavLink} to="/search" />}
                    {user.rights.includes('upload') && <Tab id='tab-import' label="Import" value="/import" component={NavLink} to="/import" />}
                    {inArray(['view','edit'],user.rights) && <Tab id='tab-images' label="Images" value="/images" component={NavLink} to="/images" />}
                    {user.rights.includes('view') && <Tab id='tab-collections' label="Collections" value="/collections" component={NavLink} to="/collections"/>}
                    <Tab label="Settings" id='tab-settings' value="/settings" component={NavLink} to="/settings" />
                </Tabs>    
            }
        </Fragment>
    );
}

export default Header;