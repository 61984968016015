import { createContext, useState, useEffect, useContext, useCallback } from 'react';

import { useApi } from "../contexts/ApiProvider";
import { useApiCalls } from '../contexts/ApiCallsProvider';
import { useFlash } from './FlashProvider';
import { handleStandardAPIResponse, replaceStateArrayElementById, 
    replaceStateArrayElementByIdAsync,
} from '../utils/utils';
import { cacheImageSource, deleteCachedImageSource } from '../utils/imageUtils';

const ImageGroupContext = createContext();

export default function ImageGroupProvider({ children }) {

    const api = useApi();
    const flash = useFlash();
    const {
        deleteImage,
        putFace,
        getImage,
        putImage,
        postDownload,
        postQueryImages,
    } = useApiCalls();

    const [ _displayContext, _setDisplayContext ] = useState('search'); // 'search' || 'confirm' || 'collection'
    const [ _queryStructure, _setQueryStructure ] = useState(null)
    const [ _collectionId, _setCollectionId ] = useState(null);
    const [ queryRunning, setQueryRunning ] = useState(false);
    const [ imagesData, setImagesData ] = useState(null); // holds ONE PAGE of images
    const [ imagesMetaData, setImagesMetaData ] = useState(null); // metadata and count for whole relevant images set
    const [ filteredImagesMetaData, setFilteredImagesMetaData ] = useState(null); // metadata from set of filtered set of images, across all pages
    const [ selectedList, setSelectedList ] = useState([]) // array of IDs for images "selected" in the group (could be for download or something else)
    const [ currentPage, setCurrentPage ] = useState(1)
    const [ imagesPageSizes, setImagePageSizes ] = useState(process.env.REACT_APP_PAGESIZES.split(','));
    const [ filters, setFilters ] = useState({locations: [], activities: [], programs: [], people: []}) // contains NAMES -- as displayed in the Select lists
  
    const getPageSize = useCallback(() => {
        if (localStorage.getItem('pageSize')) {
            return localStorage.getItem('pageSize');
        }
        else {
            return imagesPageSizes[0]
        };
    },[imagesPageSizes]);

    // Update imagesData every time the _queryStructure changes
    useEffect (() => {
        (async () => {
            
            if (_queryStructure !== null) {
               
                //if _queryStructure was updated by a page change, don't reset to page 1 -- only do that if filters changed
                const { pageChange, ...temp } = _queryStructure;
          
                //if not a page change, reset to page 1
                if (!pageChange) {
                    setCurrentPage(1);
                }

                //update query structure with current pageSize value
                temp.page_size = getPageSize();
      
                setQueryRunning(true);
                const result = await postQueryImages(temp);
                setQueryRunning(false);

                /* if (result.images.length === 0) {
                    setImagesData([]);
                    setImagesMetaData([]);
                    setFilteredImagesMetaData([]);
                    return;
                };  */   
                if (result !== undefined) {
                    setImagesData(result.images);
                    setImagesMetaData(imagesMetaData => {return {...imagesMetaData, ...result.metadata}});
                    setFilteredImagesMetaData(result.filtered_metadata);
                }    
                else {
                    setImagesData(null);
                    setImagesMetaData(null);
                    setFilteredImagesMetaData(null);
                };    
            };    
        })();    
    },[_queryStructure, postQueryImages, getPageSize])    

        
    const resetContext = (
        {
            displayContext = 'search',     // 'confirm' or 'search' or 'collection' - if not present, defaults to 'search'
            queryStructure = { search_terms: [], metadata: true }, // object w/ any relevant images query parameters - all are optional - if present, will cause image query. if absent, context remains empty.
        } = {}
    ) => {
        // if displayContext not present, set to 'search'
        // if displayContext present != current or if 'collection' and collectionId != current, then do the full reset, else ignore.
        
        let doReset;
        //if displayContext is changing, do full reset
        if (displayContext !== _displayContext) {
                _setDisplayContext(displayContext);
                doReset = true;
        }
        //if in displayContext is 'collection' and collectionID is changing, do full reset
        else if (_displayContext === 'collection' && queryStructure.collection !== _collectionId) {
            doReset = true;
        }
        //if a program is provided, do full reset
        else if (queryStructure.program !== undefined) {
            doReset = true
        }
        //otherwise no reset
        else {
            doReset = false;
        };

        if (doReset===true) {
            //use specified page size
            if ('page_size' in queryStructure) {
                localStorage.setItem('pageSize',queryStructure.page_size);
                _setQueryStructure(queryStructure);
            }
            //otherwise use last chosen page size, if available, or default to smallest in config'd list.
            else {
                if (localStorage.getItem('pageSize')) {
                    _setQueryStructure({...queryStructure, page_size: localStorage.getItem('pageSize')});
                    if (imagesPageSizes.findIndex((item) => localStorage.getItem('pageSize'))===-1) {
                        setImagePageSizes([...imagesPageSizes,localStorage.getItem('pageSize')].sort());
                    };
                }
                else {
                    _setQueryStructure({...queryStructure, page_size: imagesPageSizes[0]})
                };
            };
            
            //Shouldn't be needed because changing queryStructure will cause the Effect to run and will update imagesData anyway
            //setImagesData(null);

            _setCollectionId(queryStructure.collection);
            setSelectedList([]);
            resetFilters();
            setCurrentPage(1);
        };
    };

    const displayContext = _displayContext;
    const collectionId = _collectionId;

    const resetFilters = () => {
        setFilters({locations:[],activities:[],programs:[],people:[]})
    };

    const updateQueryStructureAfterImageAction = () => {
        // update _queryStructure
        //set last_id to first id in current imagesData
        const temp = {..._queryStructure};
        temp.last_id = Math.max(getMinImageIdInGroup()-1,1);
        temp.pageChange = true;
        _setQueryStructure(temp);
    };

    const runSearch = (terms) => {
        const temp = {..._queryStructure};
        temp.search_terms = terms.split(/[\s,]+/);
        delete temp.locations;
        delete temp.activities;
        delete temp.people;
        delete temp.programs;
        _setQueryStructure(temp);
        resetFilters();
    };

    const confirmImage = async (id) => {
        const updatedImage = await putImage(id,{
            status: 'C',
        });

        if (updatedImage !== undefined) {
            // if present, delete the thumb for this image from the cache since it will no longer be needed in this group
            deleteCachedImageSource(id,'thumb');

            // refresh image query
            updateQueryStructureAfterImageAction();

        };
        return;
    };

    const refreshImage = async (id) => {
        const updatedImage = await getImage(id);
        //remove the old image data from the array (if present) and push the new on onto it
        if (updatedImage!==undefined) {
            replaceStateArrayElementById(imagesData, id, updatedImage, setImagesData);
        };
        return;
    };

    const markImageForDeletion = async (id) => {
        const result = await deleteImage(id);
        if (result === 0) {
            // if present, delete the thumb for this image from the cache since it will no longer be needed in this group
            deleteCachedImageSource(id,'thumb');

            // refresh image query
            updateQueryStructureAfterImageAction();
            return 0;
        };
        return;
    };

    const getSingleImageData = (id) => {
        return imagesData===null ? undefined : imagesData.find(image => parseInt(image.id)===parseInt(id));
    };
    
    const getNextImageId = (currentId) => {
        if (imagesData === null) return null;
        const currentIdIndex = imagesData.findIndex((x) => x.id === parseInt(currentId))
        return currentIdIndex + 1 === ImageData.length
            ? -1
            : imagesData[currentIdIndex + 1].id;
    };

    const replaceSingleImageData = async (data) => {
        await replaceStateArrayElementByIdAsync(imagesData,data.id,data,setImagesData);
    };

    const getWidestThumb = () => {
            let temp=0;
            imagesData.map((i) => {
                if (i.width_thumb > temp) {
                    temp = i.width_thumb;
                } else if (i.width > temp) {
                    temp = i.width;
                };
                return undefined;
            })
            if (temp > 0) return temp;
            return undefined;
    };

    const toggleImageSelection = (id) => {
        const currentIndex = selectedList.indexOf(id);
        const newSelectedList = [...selectedList];
    
        if (currentIndex === -1) {
            newSelectedList.push(id);
        } else {
            newSelectedList.splice(currentIndex, 1);
        };
    
        setSelectedList(newSelectedList) ;
    };

    const getUnconfirmedFaces = () => {
        /*
            go through imagesData,
            create: facesbyPerson = {
                    personId: [{face1},{face2},...],
                    personId: [{face1},{face2},...],
                    unknown: [{face1},{face2},...]
                }
            (items in lists are face objects)
        */

        const facesByPerson = {unknown:[]};
        imagesData.map((image) => {
            if('faces' in image && image.faces.length > 0) {
                image.faces.map((face) => {
                    if ('person' in face && face.person !== null) {
                        if(face.person.id in facesByPerson) {
                            facesByPerson[face.person.id].push({...face,imageId:image.id});
                        } else {
                            facesByPerson[face.person.id]=[{...face,imageId:image.id}];
                        }
                    } else {
                        facesByPerson.unknown.push({...face,imageId:image.id});
                    };
                    return undefined;
                });
            };
            return undefined;
        });
        return facesByPerson;
    };
    
    const findImageByFace = (faceId) => {
        return imagesData.find((image) => {
            if (!('faces' in image)) return false;
            if (image.faces.length===0) return false;
            if (image.faces.findIndex((face)=>face.id===faceId)>-1) {
                return true;
            }
            return false;
        });
    };

    const sendFaceUpdate = async (id,payload) => {
        return await api.put(`/faces/${id}`,payload)
    };

    const toggleImageFaceConfirmation = async (faceId) => {

        const handleUpdateResponse = (newFace) => {
            //no changes if API failed
            if (newFace === null) return;
            
            //find the old face
            const imageIndex = imagesData.findIndex((item) => item.id===newFace.image_id);
            const faceIndex = imagesData[imageIndex].faces.findIndex((face) => face.id === faceId);
            const temp = [...imagesData]
            temp[imageIndex].faces[faceIndex].confirmed = !temp[imageIndex].faces[faceIndex].confirmed;
            setImagesData (temp);
        };
        const result = await putFace({
            id: faceId,
            confirmed: (!findImageByFace(faceId).faces.find((face)=>face.id===faceId).confirmed)
        })

        handleStandardAPIResponse(result, flash, 'The face could not be confirmed','error', handleUpdateResponse)
        return;
    };

    const addImageFace = (imageId, facesList) => {
        /*
            facesList is array of face objects, including image_id internally
            spread imagesData to temp
            find index of imageId
            add faceObj to faces element in temp by index
            setImagesData(temp)
        */

        const temp = [...imagesData];
        facesList.map((face) => {
            const imageIdx = temp.findIndex((image) => image.id===face.image_id);
            if (temp[imageIdx].faces===undefined) {
                temp[imageIdx].faces = [face];
            } else {
                temp[imageIdx].faces.push(face);
            };
            return undefined;
        });
        setImagesData(temp);
        return undefined;
    };

    const updateFacePerson = async (faceId, personId) => {

        //call the API - on success refresh image data in context
        const updatedFace = await sendFaceUpdate(faceId,{
            person_id: personId
        });

        if (!updatedFace.ok) {
            flash('The face could not be updated','error');
            return;
        };
        switch(updatedFace.status) {
            case (403):
                flash('You do not have sufficient rights to perform this function.','warning');
                break;
            case (200):
                //refresh the image in context
                refreshImage(findImageByFace(faceId).id)
                break;
            default:
                flash(updatedFace.body,'warning');
                break;
        }
        return;
    };

    const deleteFace = async (faceId) => {
        //call DELETE API - on success refresh image data in context & clear face cache
        const result = await api.delete(`/faces/${faceId}`)

        if (!result.ok) {
            flash('The face could not be deleted.','error');
            return;
        };
        switch(result.status) {
            case (403):
                flash('You do not have sufficient rights to perform this function.','warning');
                break;
            case (204):
                await refreshImage(findImageByFace(faceId).id)
                cacheImageSource(faceId,'face')
                break;
            default:
                flash(result.body,'warning');
                break;
        };
        return;
    };

    const updateImageDataEntity = async (imageId,entityName,entityId,callback=undefined) => {
        //updates image with passed-in property

        const body = {}
        body[entityName] = entityId

        const updateImage = await putImage(imageId,body);

        if (updateImage !== undefined) {
            replaceStateArrayElementById(imagesData,imageId,updateImage,setImagesData);
            if (callback) callback();
        };
        return;
    };

    const downloadImages = async (all = false) => {
        let imagesList = []
        if (all) {
            //download all images in imagesData
            imagesList = imagesData.map ((image) => image.id);
        }
        else {
            imagesList = selectedList;
        };
        const zipFile = await postDownload(imagesList);
        if (zipFile !== undefined) {
            const link= document.createElement('a');
            link.href = process.env.REACT_APP_FACES_API_BASE_URL + '/' + zipFile;
            document.body.appendChild(link);
            link.click();
            if (!all) (setSelectedList([]));
        };
        return;
    };

    const getMinImageIdInGroup = () => {
        if (imagesData !== null) {
            return Math.min(...imagesData.map((image) => parseInt(image.id)))
        };
        return 0;
    };

    const getMaxImageIdInGroup = () => {
        if (imagesData !== null) {
            return Math.max(...imagesData.map((image) => parseInt(image.id)))
        };
        return 0;
    };

    const setPageSize = (value) => {
        
        localStorage.setItem('pageSize',value)
        const temp = {..._queryStructure}
        if (value > -1) {
            temp.page_size = value;
        }
        else {
            delete temp.page_size;
        };
        delete temp.pageChange;
        delete temp.last_id;
        _setQueryStructure(temp);
    };

    const currentImagesCount = () => {
        if (filtersPopulated() === true) {
            return filteredImagesMetaData?.filtered_images_count
        }
        return imagesMetaData?.unfiltered_images_count
    }

    const getPageCount = () => {
        if (getPageSize() === -1) return 1;
        // page count is current images count div page size + 1
        return (currentImagesCount() % getPageSize() > 0)
            ? Math.floor(currentImagesCount()/getPageSize()) + 1
            : Math.floor(currentImagesCount()/getPageSize());
    };

    const showFirstPage = () => {
        //circuit breaker: if current page is first page, do nothing.
        if (currentPage === 1) return;

        setCurrentPage(1);

        //remove last_id and direction from queryStruture 
        const { last_id, direction, ...temp} = _queryStructure;
        temp.pageChange = true;
        _setQueryStructure(temp);
    };

    const showPrevPage = () => {
        //circuit breaker: if current page is first page, do nothing.
        if (currentPage === 1) return;

        setCurrentPage(currentPage - 1);

        //set last_id to first id in current imagesData
        //set direction to reverse
        const temp = {..._queryStructure};
        //temp.metadata = false;
        temp.direction = 'reverse'
        temp.last_id = getMinImageIdInGroup();
        temp.pageChange = true;
        _setQueryStructure(temp);
    }

    const showNextPage = () => {
        //circuit breaker: if current page is last page, do nothing.
        if (currentPage === getPageCount()) return;

        //increment currentPage
        setCurrentPage(currentPage + 1);

        //set last_id in queryStruture w/ max ID from imagesData
        const temp = {..._queryStructure};
        //temp.metadata = false;
        temp.direction = 'forward'
        temp.last_id = getMaxImageIdInGroup();
        temp.pageChange = true;
        _setQueryStructure(temp);
    };

    const getActivityFilterSet = () => {
        
        return imagesMetaData !== null ? imagesMetaData.activities_data : [];
    };

    const getProgramFilterSet = () => {
        
        return imagesMetaData !== null ? imagesMetaData.programs_data : [];
    };

    const getProgram = (programId) => {
        return imagesMetaData?.programs_data.find(p => p.id===programId)
    };

    const setActiveLocationFilter = (filterArray) => {
        
        
        //update filters, which controls dropdowns in UI
        setFilters({...filters, locations: filterArray})

        //update _queryStructure to re-query imagesData
        const {last_id, pageChange, ...temp} = _queryStructure;
        if (filterArray.length > 0) {
            //filters contains names -> find corresponding ids in metadata
            const locations = filterArray.map((location) => {
                return imagesMetaData.locations_data.find((item) => item.name === location).id
            })
            temp.locations = locations;
        }
        else {
            delete temp.locations;
        };
        
        _setQueryStructure(temp);
    };

    const setActiveActivityFilter = (filterArray) => {
                
        //update filters, which controls dropdowns in UI
        setFilters({...filters, activities: filterArray})

        //update _queryStructure to re-query imagesData
        const {last_id, pageChange, ...temp} = _queryStructure;
        if (filterArray.length > 0) {
            //filters contains names -> find corresponding ids in metadata
            const activities = filterArray.map((activity) => {
                return imagesMetaData.activities_data.find((item) => item.name === activity).id
            })
            temp.activities = activities;
        }
        else {
            delete temp.activities;
        };
        
        _setQueryStructure(temp);
    };

    const setActiveFilter = (filter, filterArray, labelFieldName = 'name') => {
        
        //update filters, which controls dropdowns in UI
        const filtersTemp = {...filters};
        filtersTemp[filter] = filterArray;
        setFilters(filtersTemp);

        //update _queryStructure to re-query imagesData
        const {last_id, pageChange, ...temp} = _queryStructure;
        if (filterArray.length > 0) {
            //filters contains names -> find corresponding ids in metadata
            const list = filterArray.map((x) => {
                return imagesMetaData[`${filter}_data`].find((y) => y[labelFieldName] === x).id
            })
            if (!('filters' in temp)) temp.filters = {}
            temp.filters[filter] = list;
        }
        else {
            delete temp.filters[filter];
        };
        _setQueryStructure(temp);
    };

    const filtersPopulated = () => {
        let populated = false;
        if (filters.locations.length>0) {
            populated = true;
            return populated;
        };
        if (filters.activities.length>0) {
            populated = true;
            return populated;
        };
        if (filters.programs.length>0) {
            populated = true;
            return populated;
        };
        if (filters.people.length>0) {
            populated = true;
            return populated;
        };
        return populated;
    };

    const displayFilters = () => {
        //return true if any of the filter lists are populated
        let score = 0;
        if (imagesMetaData?.programs_data?.length > 1) score +=1;
        if (imagesMetaData?.locations_data?.length > 1) score +=1;
        if (imagesMetaData?.activities_data?.length > 1) score +=1;
        if (imagesMetaData?.people_data?.length > 1) score +=1;
        return score > 0;
    };

    return (
        <ImageGroupContext.Provider value={{
            downloadImages,
            imagesData, setImagesData,
            setImagesMetaData,
            getSingleImageData, replaceSingleImageData,
            getWidestThumb,
            getActivityFilterSet,
            getProgramFilterSet,
            confirmImage,
            markImageForDeletion,
            selectedList, setSelectedList,
            toggleImageSelection,
            currentPage, setCurrentPage,
            getPageCount,
            getProgram,
            addImageFace,
            deleteFace,
            getUnconfirmedFaces,
            toggleImageFaceConfirmation,
            updateImageDataEntity,
            
            //
            collectionId,
            displayContext,
            displayFilters,
            filters,
            filteredImagesMetaData,
            filtersPopulated,
            getMaxImageIdInGroup,
            getNextImageId,
            getPageSize,
            imagesMetaData,
            imagesPageSizes,
            queryRunning,
            resetContext,
            runSearch,
            setActiveActivityFilter,
            setActiveLocationFilter,
            setActiveFilter,
            setPageSize,
            showFirstPage,
            showPrevPage,
            showNextPage,
            updateFacePerson,
            }}>
            {children}
        </ImageGroupContext.Provider>
    )
};


export function useImagesGroup() {
    return useContext(ImageGroupContext);
};