import { createContext, useCallback, useContext } from 'react';
import { useApi } from './ApiProvider';
import { useFlash } from './FlashProvider';
import { createDataUrl } from '../utils/imageUtils';
import { multiSort } from '../utils/utils';

const ApiCallsContext = createContext();

export default function ApiCallsProvider({ children }) {

    const api = useApi();
    const flash = useFlash();

    /* ===========================================
       Faces APIs
    =========================================== */
    const deleteFace = async (faceId) => {
        return await api.delete(`/faces/${faceId}`)
    };

    const getUnconfirmedFaces = async () => {
        return await api.get('/faces/unconfirmed');
    };

    const postFaces = async (facesArray) => {
        const result = await api.post('/faces',facesArray);
        if (!result.ok) {
            flash ('An error occurred adding the face. Ask Arts & Crafts for help.','error');
            return undefined;
        }    
        switch(result.status) {
            case (200):
                return result.body;
            default:
                flash(result.body,'warning');
        }        
        return undefined;
    };    

    const putFace = async (face) => {

        //remove unsupported keys for face update
        const payload = {...face}
        delete payload.person;
        delete payload.vector;
        delete payload.top;
        delete payload.right;
        delete payload.bottom;
        delete payload.left;
        delete payload.confirmed_by;
        delete payload.id;

        return await api.put(`/faces/${face.id}`,payload);
    };

    /* ===========================================
       Image APIs
       =========================================== */

    const getImage = async (id) => {
        const result = await api.get(`/images/${id}`)
        if (!result.ok) {
            flash('Images list could not be retrieved.','error');
            return undefined;
        };
        switch(result.status) {
            case (200):
                const image = result.body;
                image.faces.map((face) => {
                    face.thumbScale = 64 / (face.right - face.left);
                    return undefined;
                });
                return image;
            default:
                flash(result.body,'warning');
            };
            return undefined;
    };

    const getImageOfTheDay = async () => {
        return await api.get(`/images/daily`);
    };

    const getImageCounts = async () => {
        const result = await api.get('/images/counts');
        if (!result.ok) {
            flash('The image of the day could not be retrieved.','error');
            return undefined;
        };
        switch(result.status) {
            case (200):
                return result.body;
            default:
                flash(result.body,'warning');
            };
            return undefined;
    };

    const getUnconfirmedImagesMetaData = async () => {
        
        const result = await api.get('/images/unconfirmed/metadata');
        if (!result.ok && result.status !== 404) {
            flash('Images list could not be retrieved.','error');
            return;
        };
        switch(result.status) {
            case (404):
                return(null);
            case (200):
                return(result.body);
            default:
                flash(result.body,'warning');
        };
        return undefined;
    };

    const postQueryImages = async (queryStructure) => {
        const result = await api.post('/images/query',queryStructure);
        if (!result.ok && result.status !== 404) {
            flash('Images list could not be retrieved.','error');
            return;
        };
        switch(result.status) {
            case (404):
                return({images: []});
            case (200):
                return(result.body);
            default:
                flash(result.body,'warning');
        };
        return undefined;
    };

    const putImage = async (id, data) => {
        const result = await api.put(`/images/${id}`,data);
        if (!result.ok) {
            flash('An error occurred updating the image','error');
            return;
        };
        switch(result.status) {
            case (403):
                flash('You do not have sufficient rights to perform this function.','warning');
                break;
            case (200):
                return result.body;
            default:
                flash (result.body, 'warning');
        };
        return undefined;
    };

    const deleteImage = async (imageId) => {
        const result = await api.put(`/images/${imageId}/delete`);
        if (!result.ok) {
            flash('The image could not be marked for deletion.','error');
            return undefined;
        };
        switch(result.status) {
            case (204):
                return 0;
            default:
                flash(result.body,'warning');
        };
        return undefined;
    };

    const getFileDataUrl = useCallback (
        async (source_location) => {
        const result = await api.get('/files/' + encodeURIComponent(source_location));
        if (!result.ok || result.status === 403) {
            if (result.status !== 404) flash('An error occurred retrieving one of the images.','error');
            return 'error';
        };
        if (result.status === 200) {
            const dataUrl = await createDataUrl(result.body);
            return dataUrl;
        };
    },[api,flash]);

    const postSearchImages = useCallback (
        async (searchTerms) => {
            const result = await api.post('/images/search',
                {
                    terms: searchTerms,
                }
            );
            if (!result.ok && result.status !== 404) {
                flash ('An error occurred running the search.','error');
                return undefined;
            };
            switch(result.status) {
                case(404):
                    return [];
                case(200):
                    return result.body;
                default:
                    flash(result.body,'warning');
                };
            return undefined;
    },[api,flash]);



    /* ===========================================
       Location APIs
       =========================================== */
    const getActiveLocations = async () => {
        const result = await api.get('/locations/active');

        //if 200, sort the body
        if (result.status === 200) {
            const sorted = multiSort(result.body,[{
                sequence: 1,
                dataElement: 'name',
                sortOrder: 'asc'
            }]);
            if (sorted.status===0) {
                result.body = [...sorted.result];
            };
        };
        return result
    };

    const getAllLocations = async () => {
        return await api.get('/v2/locations/all');
    };
  
    const postLocation = async (data) => {
        const result = await api.post('/locations',{...data});
        if (!result.ok) {
            flash('The location could not be added','error');
            return undefined;
        };
        switch(result.status) {
            case (403):
                flash('You do not have sufficient rights to add a location.','warning');
                break;
            case (200):
                return result.body;
            default:
                flash(result.body,'warning');
        };
        return undefined;
    };

    /* ===========================================
       Activity APIs
       =========================================== */

    const getAllActivities = async () => {
        return await api.get('/v2/activities/all');
    };
  
    const getActiveActivities = async () => {
        return await api.get('/activities/active');
    };

    const postActivity = async (data) => {
        const result = await api.post('/activities',{...data});
        if (!result.ok) {
            flash('The activity could not be added','error');
            return undefined;
        };
        switch(result.status) {
            case (403):
                flash('You do not have sufficient rights to add an activity.','warning');
                break;
            case (200):
                return result.body;
            default:
                flash(result.body,'warning');
        };
        return undefined;
    };

    /* ===========================================
       People APIs
       =========================================== */

    const getActivePeople = async () => {
        return await api.get('/people/active')
    };

    const postPeople = async (person) => {
        return await api.post('/people',person);
    };

    /* ===========================================
       Programs APIs
       =========================================== */

    const getProgram = async (id) => {
        return await api.get(`/programs/${id}`);
    };

    const getProgramStatistics = async (id) => {
        return await api.get(`/programs/${id}/statistics`);
    };
    const getProgramWithParticipants = async (id) => {
        return await api.get(`/programs/${id}/participants`);
    }

    const getPrograms = async () => {
        return await api.get('/programs/all');
    };

    const getActivePrograms = async () => {
        return await api.get('/programs/active');
    };

    const getRecentPrograms = async () => {
        return await api.get('/programs/recent');
    };

    const putProgram = async (id, data) => {
        const result = await api.put('/programs/'+id,data);
        if (!result.ok) {
            flash('The program could not be updated','error');
            return undefined;
        }
        switch(result.status) {
            case (403):
                flash('You do not have sufficient rights to update the program.','warning');
                return undefined;
            case (200):
                return result.body;
            default:
                flash(result.body,'warning');
        }
        return undefined;
    };

    const postProgramActivity = async (prog_id, act_id) => {
        return await api.post(`/programs/${prog_id}/activities/${act_id}`);
    };

    const postProgramLocation = async (prog_id, loc_id) => {
        return await api.post(`/programs/${prog_id}/locations/${loc_id}`);
    };
    
    const deleteProgramActivity = async (prog_id, act_id) => {
        return await api.delete(`/programs/${prog_id}/activities/${act_id}`);
    };

    const deleteProgramLocation = async (prog_id, loc_id) => {
        return await api.delete(`/programs/${prog_id}/locations/${loc_id}`);
    };

    const postProgramParticipant = async (prog_id, person_id, role_id) => {
        return await api.post(`/programs/${prog_id}/participants/${person_id}/roles/${role_id}`);
    };

    const deleteProgramParticipant = async (prog_id, person_id, role_id) => {
        return await api.delete(`/programs/${prog_id}/participants/${person_id}/roles/${role_id}`);
    };

    /* ===========================================
       Files APIs
       =========================================== */

    const postDownload = async (imageIdsList) => {
        const result = await api.post('/files/download',{images: imageIdsList});

        if (!result.ok) {
            flash('Error downloading images','error');
            return undefined;
        };
        switch(result.status) {
            case (403):
                flash('You do not have sufficient rights to perform this function.','warning');
                break;
            case (200):
                return result.body.zipfile;
            default:
                flash(result.body,'warning');
            }
        return undefined;
    };

    const postUploadSpeedTest = async (data) => {
        return await api.post('/files/uploadspeedtest',{data: data})
    }

    /* ===========================================
       Users APIs
       =========================================== */
    
    const postIodSubscribe = async (actionList) => {
        const result = await api.post('/users/iod_subscription',actionList);
        
        if (!result.ok) {
            flash('Error processing IoD emails','error');
            return undefined;
        };
        switch(result.status) {
            case (403):
                flash('You do not have sufficient rights to perform this function.','warning');
                break;
                case (200):
                    return result.body;
                default:
                    flash(result.body,'warning');
        }
        return undefined;
    };

    const postPublicIodUnsubscribe = async (hash) => {
        const result = await api.post ('/iodunsubscribe',{
            hash: hash
        });
        if (!result.ok) {
            flash('An error occurred processing your request. Please try again a bit later.','error');
            return false;
        }
        else {
            return true;
        };
    };

    /* ===========================================
       Roles & Rights APIs
       =========================================== */

    const getActiveRoles = async () => {
        return await api.get('/roles/active');
    };

    const getRights = async () => {
        return await api.get('/rights');
    };
       
    const postRole = async (role) => {
        return await api.post(`/roles`,role);
    };
    
    const putRole = async (id,role) => {
        return await api.put(`/roles/${id}`,role);
    };

    /* ===========================================
       Collections APIs
       =========================================== */
    
    const getCollections = async (id) => {
        if (id) {
            return await api.get(`/collection/${id}`);
        }
        else {
            return await api.get('/collections');
        };
    };

    const postCollection = async (collections) => {
        return await api.post(`/collections`,collections);
    };

    const putCollection = async (id,collection) => {
        return await api.put(`/collections/${id}`,collection);
    };

    const deleteCollection = async (id,collection) => {
        return await api.delete(`/collections/${id}`);
    };

    const addCollectionImage = async (collection_id,image_id) => {
        return await api.put(`/collections/${collection_id}/add_image/${image_id}`);
    };

    const addCollectionImages = async (collection_id,image_ids_array) => {
        const body = image_ids_array.map((id) => {return {id: id}})
        return await api.put(`/collections/${collection_id}/add_images`,body);
    };

    const removeCollectionImage = async (collection_id,image_id) => {
        return await api.put(`/collections/${collection_id}/remove_image/${image_id}`);
    };
    
    const removeCollectionImages = async (collection_id,image_ids_array) => {
        const body = image_ids_array.map((id) => {return {id: id}})
        return await api.put(`/collections/${collection_id}/remove_images`,body);
    };

       
    // ===========================================

    return (
        <ApiCallsContext.Provider value={{
            addCollectionImage,
            addCollectionImages,
            deleteCollection,
            deleteFace,
            deleteImage,
            deleteProgramActivity,
            deleteProgramLocation,
            deleteProgramParticipant,
            getActiveActivities,
            getActiveRoles,
            getActiveLocations,
            getActivePeople,
            getActivePrograms,
            getAllActivities,
            getAllLocations,
            getCollections,
            getFileDataUrl,
            getImage,
            getImageCounts,
            getImageOfTheDay,
            getProgram,
            getPrograms,
            getProgramStatistics,
            getProgramWithParticipants,
            getRecentPrograms,
            getRights,
            getUnconfirmedImagesMetaData,
            getUnconfirmedFaces,
            postActivity,
            postCollection,
            postDownload,
            postFaces,
            postLocation,
            postSearchImages,
            postIodSubscribe,
            postPeople,
            postProgramActivity,
            postProgramLocation,
            postProgramParticipant,
            postPublicIodUnsubscribe,
            postQueryImages,
            postRole,
            postUploadSpeedTest,
            putCollection,
            putFace,
            putImage,
            putProgram,
            putRole,
            removeCollectionImage,
            removeCollectionImages,
        }}>
            {children}
        </ApiCallsContext.Provider>
    );

};

export function useApiCalls() {
    return useContext(ApiCallsContext);
};