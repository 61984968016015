import { useEffect, useState } from "react";
import { Card, CardActionArea, CardContent, CardMedia, Typography } from "@mui/material";
import { ImageNotSupported, Warning } from "@mui/icons-material";

import { cacheImageSource, getCachedImageSource } from '../utils/imageUtils';
import CircularIndeterminate from "./CircularIndeterminate";

import { useStyles } from "../contexts/StylesProvider";
import { useFlash } from '../contexts/FlashProvider';
import { useApiCalls } from "../contexts/ApiCallsProvider";
import { useNavigate } from "react-router-dom";

const ProgramCardMini = ({
    programData,    //object with data for current program
    leftImage = false,      //true -> image on the left, false -> image on the right
}) => {

    const { getFileDataUrl } = useApiCalls();
    const flash = useFlash();
    const navigate = useNavigate();
    const styles = useStyles();
    
    const [ ,setCacheUpdated ] = useState(false)

    useEffect(() => {
        if (programData.program_image) {
            (async () => {
                if (!getCachedImageSource(programData.program_image.id,'thumb')) {
                    await cacheImageSource([programData.program_image.id], 'thumb',await getFileDataUrl(programData.program_image.thumb_location));
                    setCacheUpdated(true);
                };
            })();
        }
    },[flash, setCacheUpdated, programData, getFileDataUrl]);

    const renderProgramName = () => {
       return (
            <Typography sx={{...styles.textCardTitle, fontSize: styles.textCardTitle.fontSize - 3}}>{programData.label}</Typography>
       );
    };

    const handleClick = () => {
        if (!('program_image' in programData)) {
            navigate('/import');
        }
        else {
            navigate('/search/'+programData.id);
        };
    };

    if (programData) {

        return (
            <Card
                sx={{ maxWidth: 200, maxHeight: 100, my: 1 }}
            >
                <CardActionArea
                    sx={{ display: 'flex', flexDirection: 'row' }}
                    onClick={handleClick}
                >
                    {leftImage === true && <CardContent sx={{ p:0, ml: 1, mt: 1, mr: 1 }}>
                        {renderProgramName()}
                    </CardContent>}
                    {// if no program image, display icon
                        !('program_image' in programData) &&
                        <CardMedia
                            id={programData.id+'no-image-icon'}
                            style={{ display: 'flex', flexDirection: 'column', alignItems: 'center', justifyContent: 'center', height: 100, width: 100 }}
                        >
                            <ImageNotSupported color='secondary'sx={{ mb: 1 }} />
                            <Typography variant='caption' color='secondary'>Import</Typography>
                        </CardMedia>
                    }
                    {// if no image cache, display spinner
                        programData.program_image && !getCachedImageSource(programData.program_image.id,'thumb') &&
                        <CardMedia
                            id={programData.program_image_id}
                            style={{ display: 'flex', alignItems: 'center', justifyContent: 'center', height: 100 }}
                        >
                            <CircularIndeterminate />
                        </CardMedia>
                    }
                    {// if image cache error, display icon
                        programData.program_image && getCachedImageSource(programData.program_image.id,'thumb') === 'error' &&
                        <CardMedia
                            id={programData.program_image_id}
                            style={{ display: 'flex', alignItems: 'center', justifyContent: 'center', height: 100 }}
                        >
                            <Warning />
                        </CardMedia>
                    }
                    {// use image cache if available
                        programData.program_image && getCachedImageSource(programData.program_image.id,'thumb') &&
                        <CardMedia
                            component="img"
                            style={{height: 100, width: 100}}
                            id={programData.program_image_id}
                            image={getCachedImageSource(programData.program_image.id,'thumb')}
                        />
                    }
                    {leftImage === false && <CardContent sx={{ p:0, ml: 1, mt: 1, mr: 1 }}>
                        {renderProgramName()}
                        {/* <Typography variant='caption'>{new Date(programData.start_date).toLocaleDateString('en-US',{dateStyle: 'medium'})} - {new Date(programData.end_date).toLocaleDateString('en-US',{dateStyle: 'medium'})}</Typography> */}
                    </CardContent>}
                </CardActionArea>
            </Card>
        );

    };
  
    return null;

};

export default ProgramCardMini;