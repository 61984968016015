import { Fragment, useEffect, useState } from "react";
import CircularIndeterminate from "./CircularIndeterminate";
import ProgramCardMini from "./ProgramCardMini";
import { useApiCalls } from "../contexts/ApiCallsProvider";
import { useFlash } from "../contexts/FlashProvider";
import { handleStandardAPIResponse } from "../utils/utils";
import { Typography } from "@mui/material";

const RecentPrograms = () => {
    
    const { getRecentPrograms } = useApiCalls();
    const flash = useFlash();
    const [ programsData, setProgramsData ] = useState();
    
    useEffect(() => {
        //fetch recent programs
        (async () => {
            const progs = await getRecentPrograms();
            handleStandardAPIResponse(progs, 'Recent programs could not be loaded',flash,'Error',setProgramsData)
        })();
    },[flash, getRecentPrograms]);

    if (programsData) {
        return (
            <Fragment>
                <Typography color='primary' variant='h6' sx={{ textAlign: 'center' }}>Recent Programs</Typography>
                {programsData.map((p,i) => {
                    return (
                        <ProgramCardMini key={i} programData={p} leftImage={i % 2 === 0} sx={{ my: 1 }}/>
                    );
                })}
            </Fragment>
        );
    };
    return (
        <CircularIndeterminate />
    );

};



export default RecentPrograms;